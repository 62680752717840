@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

/* Assure que le body et le html prennent toute la hauteur disponible */
html, body {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif; /* Utilise une police de caractères générique */
}

/* Configure le conteneur principal pour utiliser Flexbox et occuper toute la hauteur de la fenêtre */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Assure que le conteneur occupe au moins toute la hauteur de la fenêtre */
}

footer {
  margin-top: auto; /* Pousse le footer en bas de la page lorsque le contenu est insuffisant */
}

/* Optionnel : styles globaux pour le texte et les liens */
a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Styles pour les sections avec transition */
.transition-all {
  transition: all 0.3s ease;
}

.max-h-0 {
  max-height: 0;
  overflow: hidden;
}

.max-h-screen {
  max-height: 100vh; /* Assure que la hauteur maximale est suffisante pour le contenu */
}